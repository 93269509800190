<h1>To-do</h1>

<app-card>
  <app-card [flexDirection]="'row'">
    <span>Show Deleted</span>
    <input type="checkbox" [checked]="showDeleted" (change)="onShowDeletedToggle()" />
  </app-card>
  <app-card [flexDirection]="'row'">
    <span>Show Add Item</span>
    <input type="checkbox" [checked]="showAddItemUI" (change)="onShowAddItemUIToggle()" />
  </app-card>
</app-card>

@if(showAddItemUI) {
  <app-card>
    <span>Add Item</span>
    <span>Title</span>
    <input type="text" [(ngModel)]="addTodoInput.title" />
    <span>Description</span>
    <app-button [label]="'Add'" (clickEvent)="onAddItemClick()" />
  </app-card>
}

<app-card class="todo-container" [width]="'auto'" [borderStyle]="'shadow'">
  @for(todo of todoItems; track todo.id) {
    @if(showDeleted || !todo.deletedFlag) {
      <app-card class="todo-container__item" [borderStyle]="'shadow'" [padding]="'none'">
        <h3>{{ todo.title }}</h3>
        <span>{{ todo.description }}</span>
        @if (todo.completionDate) {
          <span>Completed On: {{ todo.completionDate }}</span>
        }

        <app-card [flexDirection]="'row'" >
          @if (todo.deletedFlag) {
            <span class="__deleted">Deleted</span>
          } @else {
            <button (click)="onDeleteClick(todo)">Delete</button>
            <input type="checkbox" [checked]="todo.completionDate" (change)="onCompletionToggle(todo)" />
          }
        </app-card>
      </app-card>
    }
  }
</app-card>
