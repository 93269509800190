<app-card>
  <h1>Translation Dictionary</h1>

  <app-card
    id="translations-container"
    [flexDirection]="'row'"
    [width]="'oneHundredPercent'"
    [flexWrap]="true" id="translation-container"
    [justifyContent]="'space-between'"
    [gapWidth]="'10px'"
  >
    @for (translation of translations; track $index) {
      @if (translation.word.name && translation.translations.length > 0) {
        <app-translation-card [translatedWord]="translation" class="translation-card"/>
      }
    }
  </app-card>

</app-card>
