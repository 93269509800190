<div>
  <h2>Date: {{ day.date }}</h2>
  @for (tag of day.tags; track day) {
    <span>{{ tag }}</span>
  }
  <h2>Notes:</h2>
  <div>
    @for (note of day.notes; track day) {
      <p>{{ note }}</p>
    }
  </div>
</div>
<div>
  <h3>Tasks</h3>
  <div>
    <app-planner-task-viewer [tasks]="day.tasks"/>
  </div>
</div>
