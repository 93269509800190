<app-card class="exercises-container" [padding]="'none'" [width]="'oneHundredPercent'">
  <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
    Exercises: {{ exercises | json }}
    <mat-tab label="Table">
      <app-exercise-viewer [exercises]="exercises" [columnsToRender]="columnsToRender" [view]="'table'"/>
    </mat-tab>
    <mat-tab label="Cards">
      <app-exercise-viewer [exercises]="exercises" [columnsToRender]="columnsToRender" [view]="'card'"/>
    </mat-tab>
    <mat-tab label="Cards Drag'n'drop">
      <app-exercise-viewer [exercises]="exercises" [columnsToRender]="columnsToRender" [view]="'card-drag-drop'"/>
    </mat-tab>
    <mat-tab label="Add Exercise">
      <app-exercise-add/>
    </mat-tab>
  </mat-tab-group>
</app-card>
