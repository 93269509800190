<mat-toolbar color="primary" [ngClass]="'--' + displayType" >
  <!-- <button mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon" (click)="emitToggleSidenav()">
    <mat-icon>menu</mat-icon>
  </button> -->

  <button mat-icon-button class="example-icon favorite-icon" aria-label="Example icon-button with heart icon" (click)="onClickNavigate('back')">
    <mat-icon>arrow_backward</mat-icon>
  </button>

  <button mat-icon-button class="example-icon favorite-icon" aria-label="Example icon-button with heart icon" (click)="onClickNavigate('default')">
    <mat-icon>home</mat-icon>
  </button>

  <button mat-icon-button class="example-icon favorite-icon" aria-label="Example icon-button with heart icon" (click)="onClickNavigate('forward')">
    <mat-icon>arrow_forward</mat-icon>
  </button>

  <button (click)="onForceUpdateClick()" mat-icon-button class="example-icon" aria-label="Force app update">
    <mat-icon>update</mat-icon>
  </button>

  @if (displayType === 'top') {
    <span class="example-spacer"></span>

    <span>{{ appName }}</span>
  }

</mat-toolbar>
