import { IndexedDBModule } from "@Api/indexedDb/indexedDB.service";
import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";
import { RouterOutlet } from "@angular/router";
import { GlobalHelperService } from "./@services/global-helper.service";
import { AppRouterService } from "./@services/app-router.service";
import { AppStorageService } from "./@services/app-storage.service";
import { APP_ROUTES } from "@Constants/globals";
import { DeviceDetectorService, DeviceInfo } from "ngx-device-detector";
import { IAppRoute } from "@Models/components";
import { NavbarComponent } from "@Components/navbar/navbar.component";
import { SwUpdateService } from "./SwUpdateDetector.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    IndexedDBModule,
    NavbarComponent,
  ],
  providers: [
    GlobalHelperService,
    AppRouterService,
    AppStorageService,
    SwUpdateService
  ],
})
export class AppComponent {
  title = 'Project Sphynx';
  sidenavToggled = false;
  appRoutes = APP_ROUTES;
  navigableRoutes: IAppRoute[];

  deviceInfo: DeviceInfo;
  showDesktopView = false;

  constructor(
    private _appRouter: AppRouterService,
    private _deviceDetectorService: DeviceDetectorService,
    private _appStorageService: AppStorageService
  ) {
    this.generateRoutesToRender();
    this.determineDeviceType();
  }

  determineDeviceType(): void {
    this.deviceInfo = this._deviceDetectorService.getDeviceInfo();
    this.showDesktopView = this._deviceDetectorService.isDesktop();
    this._appStorageService.setLocalStorageItem('isDesktopView', this.showDesktopView);
  }

  private generateRoutesToRender(): void {
    this.navigableRoutes = this.appRoutes.filter(route => route.includeInNav);
  }

  onToggleSidenav() {
    this.sidenavToggled = !this.sidenavToggled;
  }

  onNavigationButtonClick(route: IAppRoute): void {
    this._appRouter.navigateTo(route.path);
    this.sidenavToggled = false;
  }
}
