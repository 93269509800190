import { Component, HostBinding, Input } from '@angular/core';
import { ComponentPadding, CardBorderStyle, ComponentFlexDirection, ComponentElevation, ComponentJustifyContent, ComponentWidthStyle } from '@Models/types';

@Component({
  selector: 'app-card',
  standalone: true,
  templateUrl: './card.component.html',
  styleUrl: './card.component.scss'
})
export class CardComponent {
  @Input() padding: ComponentPadding = 'medium';
  @Input() borderStyle: CardBorderStyle = 'none';
  @Input() flexDirection: ComponentFlexDirection = 'column';
  @Input() elevation: ComponentElevation = 'none';
  @Input() justifyContent: ComponentJustifyContent = 'flex-start';
  @Input() alignItems: ComponentJustifyContent = 'flex-start';
  @Input() width: ComponentWidthStyle = 'auto';
  @Input() flexWrap: boolean = false;
  @Input() gapWidth: string = '0';
  @HostBinding('class.mat-elevation-z2') get isHighlightElevation() {
    return this.elevation === 'highlight';
  }
  @HostBinding('class.mat-elevation-z6') get isRaisedElevation() {
    return this.elevation === 'raised';
  }

  flexDirectionRecord: Record<ComponentFlexDirection, string> = {
    column: 'column',
    row: 'row'
  };

  paddingRecord: Record<ComponentPadding, string> = {
    none: '0',
    small: '0.5rem',
    medium: '1rem',
    large: '2rem'
  };

  widthRecord: Record<ComponentWidthStyle, string> = {
    oneHundredPercent: '100%',
    screenWidth: '100vw',
    auto: 'auto'
  };
}
