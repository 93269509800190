<app-feature-frame>
  <div class="dashboard-container">
    <h1>Welcome to Project Sphynx</h1>
    <h3>Environment: {{ envName }}</h3>
    <h3>Connected to API: {{ apiURL }}</h3>

    <div class="dashboard-routes-container">
      @for (route of appRoutes; track $index) {
        <mat-card class="dashboard-route__card" appearance="raised" (click)="navigateToRoute(route.path)">
          <mat-card-header>
            {{ route.title}}
          </mat-card-header>
          <mat-card-content>
          </mat-card-content>
          @if (route.icon) {
            <div class="card__icon-container">
              <mat-icon class="card__icon" [fontIcon]='route.icon'/>
            </div>
          }
        </mat-card>
      }
    </div>
  </div>
</app-feature-frame>
