import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ENVIRONMENT } from "environments/environment";
import { DeviceDetectorService, DeviceInfo } from "ngx-device-detector";
import { BehaviorSubject, firstValueFrom, interval } from "rxjs";

export enum ServerConnectionStatus { Initialising, Connected, Disconnected }

export interface IApiHandshakeResponse {
  status: number;
  message: string;
}

@Injectable({ providedIn: 'root' })
export class APIService {
  apiUrl = ENVIRONMENT.API_URL as string;
  envName = ENVIRONMENT.ENV_NAME as string;
  accessToken = ENVIRONMENT.ACCESS_TOKEN as string;

  serverConnectionStatus$ = new BehaviorSubject<ServerConnectionStatus>(ServerConnectionStatus.Initialising);

  headers = new HttpHeaders({
    'x-access-token': this.accessToken,
  })


  constructor(
    private _httpClient: HttpClient,
  ) {
    this.apiHandshake();
    // const serverHandhshakeInterval$ = interval(30000);
    // serverHandhshakeInterval$.subscribe(() => {
    //   this.apiHandshake();
    // });
  }

  private apiHandshake(): Promise<void> {
    const handshakeUrl = this.constructApiUrl('handshake');
    return firstValueFrom(this._httpClient.get<IApiHandshakeResponse>(handshakeUrl, { headers: this.headers }))
    .then((res) => {
      const serverIsAvailable = res.status === 200;
      if (!serverIsAvailable) {
        this.serverConnectionStatus$.next(ServerConnectionStatus.Disconnected);
      }
      console.info('API:', this.apiUrl, `\nENV: ${this.envName}\nHandshake: ${res.status}\nAPI Available: ${serverIsAvailable ? 'Yes' : 'No'}`);
      return Promise.resolve();
    });
  }

  // private handshakeCheck(): Promise<boolean> {
  //   if (this.serverConnectionStatus$.value === ServerConnectionStatus.Connected) {
  //     return Promise.resolve(true);
  //   } else {
  //     return this.apiHandshake().then(() => {
  //       return Promise.resolve(this.serverConnectionStatus$.value === ServerConnectionStatus.Connected);
  //     })
  //   }
  // }

  get<T>(endpoint: string): Promise<T> {
    const getUrl = this.constructApiUrl(endpoint);
    return firstValueFrom(this._httpClient.get<T>(getUrl, { headers: this.headers }));
  }

  private constructApiUrl(endpoint: string): string {
    return `${this.apiUrl}${endpoint}`;
  }
}
