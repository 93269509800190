<app-feature-frame id="dev-tools">
  <mat-card>
    <mat-card-header>
      <h1>Developer Tools</h1>
    </mat-card-header>
    <mat-card-content>
      <p>Env: {{ envName }}</p>
      <p>API: {{ apiURL }}</p>
    </mat-card-content>
  </mat-card>
</app-feature-frame>
