import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatCard, MatCardModule } from '@angular/material/card';
import { FeatureFrameComponent } from '@Ui/feature-frame/feature-frame.component';
import { ENVIRONMENT } from 'environments/environment';

@Component({
  selector: 'app-feature-dev-tools',
  standalone: true,
  imports: [
    CommonModule,
    FeatureFrameComponent,
    MatCard,
    MatCardModule
  ],
  templateUrl: './dev-tools.component.html',
  styleUrl: './dev-tools.component.scss'
})
export class DevToolsFeatureComponent {
  envName = ENVIRONMENT.ENV_NAME as string;
  apiURL = ENVIRONMENT.API_URL as string;
}
