<app-feature-frame>
  <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
    <mat-tab label="Profile">
      <app-profile/>
    </mat-tab>
    <mat-tab label="Account">
      <app-account/>
    </mat-tab>
  </mat-tab-group>
</app-feature-frame>
