import { Component } from '@angular/core';
import { IPlannerDay } from './planner.types';
import { PLANNER_DAY } from '@Constants/planner-hardcoded';
import { PlannerDayComponent } from './planner-day/planner-day.component';
import { FeatureFrameComponent } from '@Ui/feature-frame/feature-frame.component';

@Component({
  selector: 'app-planner',
  standalone: true,
  imports: [
    PlannerDayComponent,
    FeatureFrameComponent
  ],
  templateUrl: './planner.component.html',
  styleUrl: './planner.component.scss'
})
export class PlannerFeatureComponent {
  dayOneExample: IPlannerDay = PLANNER_DAY;

  plannerDays: IPlannerDay[] = [
    this.dayOneExample
  ];
}
