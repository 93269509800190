import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatTableModule } from '@angular/material/table';
import { FitnessApiService } from '@Api/fitness.api.service';
import { HARDCODED_ROUTINES } from '@Constants/trainer-dashboard-hardcoded-data';
import { IWorkout } from '@Models/trainer';
import { CardComponent } from '@Ui/card/card.component';

@Component({
  selector: 'app-trainer-library-routines',
  standalone: true,
  imports: [
    CommonModule,
    MatTableModule,
    MatDividerModule,
    DragDropModule,
    CardComponent
  ],
  templateUrl: './routines.component.html',
  styleUrl: './routines.component.scss'
})
export class TrainerRoutinesComponent {
  columnsToRender: string[] = ['id', 'name', 'description', 'category', 'workouts'];
  routines = HARDCODED_ROUTINES;

  constructor(
    private _fitnessApiService: FitnessApiService
  ) {
    _fitnessApiService.getAllRoutines().then(routines => {
      this.routines = routines
    });
  }

  generateRenderableWorkouts(workouts: IWorkout[]): string {
    return workouts.map(workout => workout.name).join(', ');
  }
}
