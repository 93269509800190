import { LanguagesApiService } from '@Api/languages.api.service';
import { ITranslatedWord, IWordLanguage } from '@Models/languages';
import { CardComponent } from '@Ui/card/card.component';
import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { TranslationCardComponent } from './translation-card/translation-card.component';

@Component({
  selector: 'app-feature-languages',
  standalone: true,
  imports: [
    CommonModule,
    CardComponent,
    TranslationCardComponent
  ],
  templateUrl: './languages-dashboard.component.html',
  styleUrl: './languages-dashboard.component.scss'
})
export class LanguagesFeatureComponent {
  translations: ITranslatedWord[];
  spanishTranslations: IWordLanguage[];

  constructor(
    private _spanishDataService: LanguagesApiService
  ) {
    this._spanishDataService.fetchAllTranslatedWords().then(translatedWords => {
      this.translations = translatedWords;
    });
  }

}
