<app-feature-frame [padding]="'none'">
  <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
    <mat-tab label="Exercises">
      <app-trainer-library-exercises/>
    </mat-tab>
    <mat-tab label="Workouts">
      <app-trainer-library-workouts/>
    </mat-tab>
    <mat-tab label="Routines">
      <app-trainer-library-routines/>
    </mat-tab>
  </mat-tab-group>
</app-feature-frame>
