import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ButtonComponent } from '@Ui/button/button.component';
import { CardComponent } from '@Ui/card/card.component';
import { DateTime } from 'luxon';

export interface ITodoItem {
  id: number;
  title: string;
  description?: string;
  completionDate?: string;
  deletedFlag?: boolean;
}

@Component({
  selector: 'app-feature-todo',
  standalone: true,
  imports: [
    CommonModule,
    CardComponent,
    ButtonComponent,
    FormsModule
  ],
  templateUrl: './todo.component.html',
  styleUrl: './todo.component.scss'
})
export class TodoFeatureComponent {
  todoItems: ITodoItem[] = [];
  showDeleted = false;
  showAddItemUI = false;

  addTodoInput: ITodoItem = {} as unknown as ITodoItem;

  ngOnInit(): void {
    this.insertMockTodoItems();
  }

  insertMockTodoItems(): void {
    const mockTodoItems: ITodoItem[] = [
      {
        id: 1,
        title: 'First todo item',
        description: 'This is the first todo item'
      },
      {
        id: 2,
        title: 'Second todo item',
        description: 'This is the second todo item',
        completionDate: ''
      }
    ]

    this.todoItems.push(...mockTodoItems)
  }

  onCompletionToggle(todoItem: ITodoItem): void {
    todoItem.completionDate = todoItem.completionDate ? undefined : DateTime.utc().toISO();
  }

  // Should we flag items as deleted, or actually delete them?
  onDeleteClick(todoItem: ITodoItem): void {
    todoItem.deletedFlag = !todoItem.deletedFlag;
  }

  onShowDeletedToggle(): void {
    this.showDeleted = !this.showDeleted;
  }

  onShowAddItemUIToggle(): void {
    this.showAddItemUI = !this.showAddItemUI;
  }

  onAddItemClick(): void {
    if (this.verifyAddItemInputIsValid()) {
      console.log(`Added item:\n`, this.addTodoInput)
      this.todoItems.push(this.addTodoInput);
    }
  }

  verifyAddItemInputIsValid(): boolean {
    const hasMandatoryFields = !!this.addTodoInput.title;
    return hasMandatoryFields;
  }
}
