import { IW40kDatacard } from '@Models/tabletop';
import { CardComponent } from '@Ui/card/card.component';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-w40k-datacard',
  standalone: true,
  imports: [
    CardComponent
  ],
  templateUrl: './w40k-datacard.component.html',
  styleUrl: './w40k-datacard.component.scss'
})
export class W40kDatacardComponent {
  @Input() datacard: IW40kDatacard;
}
