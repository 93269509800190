<app-card>
  <span>Add Exercise</span>
  <span>Name</span>
  <input type="text" [(ngModel)]="newExerciseInput.name" />
  <span>Description</span>
  <input type="text" [(ngModel)]="newExerciseInput.description" />
  <span>Category</span>
  <input type="text" [(ngModel)]="newExerciseInput.category" /> <!-- Make this a dropdown -->
  <app-button [label]="'Add'" (clickEvent)="onAddExerciseClick()" />

  <mat-select>
    <mat-option></mat-option>
    <mat-option></mat-option>
  </mat-select>
</app-card>

<app-card class="exercise-container" [width]="'auto'" [borderStyle]="'shadow'">
  @for(exercise of exercises; track exercise.id) {
    <app-card class="exercise-container__item" [borderStyle]="'shadow'" [padding]="'none'">
      <h3>{{ exercise.name }}</h3>
      <span>{{ exercise.description }}</span>
      <span>{{ exercise.category }}</span>
    </app-card>
  }
</app-card>
