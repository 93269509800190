import { Component, Input } from '@angular/core';
import { IPlannerTask } from '@Features/planner/planner.types';
import { PlannerTaskComponent } from '../planner-task/planner-task.component';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-planner-task-viewer',
  standalone: true,
  imports: [
    PlannerTaskComponent,
    CommonModule
  ],
  templateUrl: './planner-task-viewer.component.html',
  styleUrl: './planner-task-viewer.component.scss'
})
export class PlannerTaskViewerComponent {
  @Input() tasks: IPlannerTask[];
}
