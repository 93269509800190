import { Component, Input } from '@angular/core';
import { IPlannerTask } from '../planner.types';
import { CardComponent } from "../../../@ui/card/card.component";

@Component({
  selector: 'app-planner-task',
  standalone: true,
  imports: [
    CardComponent
  ],
  templateUrl: './planner-task.component.html',
  styleUrl: './planner-task.component.scss'
})
export class PlannerTaskComponent {
  @Input() task: IPlannerTask;
}
