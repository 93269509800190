import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { CardComponent } from '@Ui/card/card.component';
import { FeatureFrameComponent } from '@Ui/feature-frame/feature-frame.component';
import { TrainerLibraryComponent } from './trainer-library/trainer-library.component';
import { TrainerDynamicWorkoutComponent } from './trainer-dynamic-workout/trainer-dynamic-workout.component';

@Component({
  selector: 'app-feature-trainer',
  standalone: true,
  imports: [
    CommonModule,
    CardComponent,
    FeatureFrameComponent,
    MatTabsModule,
    TrainerLibraryComponent,
    TrainerDynamicWorkoutComponent
  ],
  templateUrl: './trainer.component.html',
  styleUrl: './trainer.component.scss'
})
export class TrainerFeatureComponent {

}
