import { ITranslatedWord } from '@Models/languages';
import { CardComponent } from '@Ui/card/card.component';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-translation-card',
  standalone: true,
  imports: [
    CardComponent
  ],
  templateUrl: './translation-card.component.html',
  styleUrl: './translation-card.component.scss'
})
export class TranslationCardComponent {
  @Input() translatedWord: ITranslatedWord;
}
