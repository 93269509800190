import { Component, Input } from '@angular/core';
import { IPlannerDay } from '../planner.types';
import { PlannerTaskViewerComponent } from '../planner-task-viewer/planner-task-viewer.component';

@Component({
  selector: 'app-planner-day',
  standalone: true,
  imports: [
    PlannerTaskViewerComponent
  ],
  templateUrl: './planner-day.component.html',
  styleUrl: './planner-day.component.scss'
})
export class PlannerDayComponent {
  @Input() day: IPlannerDay;
}
