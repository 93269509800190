<div class="card"
  [style.flex-direction]="flexDirectionRecord[flexDirection]"
  [style.padding]="paddingRecord[padding]"
  [class.--border-style-standard]="borderStyle === 'standard'"
  [class.--border-style-shadow]="borderStyle === 'shadow'"
  [style.justify-content]="justifyContent"
  [style.align-items]="alignItems"
  [style.width]="widthRecord[width]"
  [style.flex-wrap]="flexWrap ? 'wrap' : ''"
  [style.gap]="gapWidth"
  >
  <ng-content/>
</div>
