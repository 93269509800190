<main class="main">
  @if (showDesktopView) {
    <div class="device-view desktop-view">
      <app-navbar class="toolbar toolbar--top" [displayType]="'top'" (toggleSidenav)="onToggleSidenav()"/>
      <div class="app-content app-content--desktop">
        <router-outlet/>
      </div>
    </div>
  } @else {
    <div class="device-view mobile-view">
      <div class="app-content app-content--mobile">
        <router-outlet/>
      </div>
      <app-navbar class="toolbar toolbar--bottom" [displayType]="'bottom'" (toggleSidenav)="onToggleSidenav()"/>
    </div>
  }
</main>
