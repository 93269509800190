<app-feature-frame [padding]="'none'">
  <mat-tab-group>
    <mat-tab [label]="'Dynamic Workout'">
      <app-trainer-dynamic-workout/>
    </mat-tab>
    <mat-tab [label]="'Library'">
      <app-trainer-library/>
    </mat-tab>
  </mat-tab-group>
</app-feature-frame>
