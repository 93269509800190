<app-card>
  <h2>{{ task.id }}: {{ task.title }}</h2>
  <p>{{ task.tags }}</p>
  <p>{{ task.notes }}</p>
  <div>
    <span>Priority: {{ task.priority }}</span>
    <span>Status: {{ task.status }}</span>
  </div>
  <p>{{ task.description }}</p>
</app-card>
