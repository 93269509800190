import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { FitnessApiService } from '@Api/fitness.api.service';
import { AppStorageService } from '@App/@services/app-storage.service';
import { IDynamicWorkout, IExercise } from '@Models/trainer';

type TableViewMode = 'Planning (Simple)' | 'Planning (Detailed)' | 'Workout';

@Component({
  selector: 'app-trainer-dynamic-workout',
  standalone: true,
  imports: [
    MatButtonModule,
    MatTableModule
  ],
  templateUrl: './trainer-dynamic-workout.component.html',
  styleUrl: './trainer-dynamic-workout.component.scss'
})
export class TrainerDynamicWorkoutComponent {
  exerciseLibrary: IExercise[];
  dynamicWorkout: IDynamicWorkout;

  tableViewMode: TableViewMode = 'Planning (Detailed)';
  columnsToRender: { [view: string]: string[] } = {
    "Planning (Simple)": ['name', 'addRemove'],
    "Planning (Detailed)": ['name', 'category', 'variant', 'primaryMuscle', 'secondaryMuscle', 'addRemove'],
    "Workout": ['name', 'variant', 'setControls']
  };

  desktopView: boolean;

  constructor(
    private _fitnessApiService: FitnessApiService,
    private _appStorageService: AppStorageService,
    private _snackBar: MatSnackBar,
  ) {
    this.initialiseComponent();
  }

  initialiseComponent(): void {
    this.desktopView = this._appStorageService.getLocalStorageItem<boolean>('isDesktopView') ?? true;
    if (!this.desktopView) {
      this.tableViewMode = 'Planning (Simple)';
    }

    this._fitnessApiService.getAllExercises().then(exercises => {
      this.exerciseLibrary = exercises
    });
  }

  onCreateDynamicWorkout(): void {
    this.dynamicWorkout = {
      id: null,
      name: '',
      description: '',
      category: null,
      exercises: []
    }
  }

  onLoadDynamicWorkout(): void {
    const savedDynamicWorkout = this._appStorageService.getLocalStorageItem<IDynamicWorkout>('dynamicWorkout');
    if (savedDynamicWorkout) {
      this.openSnackBar('Loaded previous dynamic workout');
      this.dynamicWorkout = savedDynamicWorkout;
    } else {
      this.openSnackBar('No previous dynamic workout found, creating a new one');
      this.onCreateDynamicWorkout();
    }
  }

  onSaveDynamicWorkout(): void {
    this._appStorageService.setLocalStorageItem('dynamicWorkout', this.dynamicWorkout);
    this.openSnackBar('Dynamic workout saved', 'Undo');
  }

  onResetDynamicWorkout(): void {
    this.onCreateDynamicWorkout();
    this.openSnackBar('Dynamic workout reset');
  }

  onToggleSimpleView(): void {
    const modeIndex = Object.keys(this.columnsToRender).indexOf(this.tableViewMode);
    const modeKeys = Object.keys(this.columnsToRender);
    const nextIndex = (modeIndex + 1) % modeKeys.length;
    this.tableViewMode = modeKeys[nextIndex] as TableViewMode;
  }

  onAddExercise(exercise: IExercise): void {
    if (!this.dynamicWorkout.exercises.find(e => e.id === exercise.id)) {
      //We use immutability since changeDetection wasn't triggering for .push()
      this.dynamicWorkout.exercises = [...this.dynamicWorkout.exercises, exercise];
    } else {
      this.openSnackBar('Exercise already included, cannot add duplicates');
    }
  }

  onRemoveExercise(exercise: IExercise): void {
    this.dynamicWorkout.exercises = this.dynamicWorkout.exercises.filter(ex => ex.id !== exercise.id);
  }

  openSnackBar(message: string, actionMessage?: string) {
    this._snackBar.dismiss();
    this._snackBar.open(message, actionMessage ?? '', {
      horizontalPosition: 'left',
      verticalPosition: 'bottom',
      duration: 3000
    });
  }
}
