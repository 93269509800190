import { AppRouterService } from '@App/@services/app-router.service';
import { APP_NAME, APP_ROUTES } from '@Constants/globals';
import { NavigationAction, NavigationDisplay } from '@Models/types';
import { ButtonComponent } from '@Ui/button/button.component';
import { CardComponent } from '@Ui/card/card.component';
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';

@Component({
  selector: 'app-navbar',
  standalone: true,
  imports: [
    CommonModule,
    ButtonComponent,
    CardComponent,
    MatSidenavModule,
    MatCheckboxModule,
    FormsModule,
    MatButtonModule,
    MatIconModule,
    MatToolbarModule
  ],
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.scss'
})
export class NavbarComponent {
  routes = APP_ROUTES
  appName = APP_NAME

  @Input() displayType: NavigationDisplay = 'top';
  @Output() toggleSidenav = new EventEmitter<void>();

  constructor(
    private _appRouter: AppRouterService
  ) {}

  onClickNavigate(action: NavigationAction, route?: string, ) {
    switch (action) {
      case 'default':
        this._appRouter.navigateToDefaultRoute();
      break;
      case 'to':
        if (route) {
          this._appRouter.navigateTo(route);
        }
        break;
      case 'back':
        this._appRouter.navigateBack();
        break;
      case 'forward':
        this._appRouter.navigateForward();
        break;
    }
  }

  emitToggleSidenav() {
    this.toggleSidenav.emit();
  }

  onForceUpdateClick() {
    window.location.reload();
  }
}
