<app-card [elevation]="'raised'" class="translation-card">
  @if (!!translatedWord.word.definition) {
    <p>Definition: {{ translatedWord.word.definition }}</p>
  }
  @for (translation of translatedWord.translations; track $index) {
    <app-card>
      <h2>{{ translatedWord.word.name }}</h2>
      <h3>{{ translation.translation }}</h3>
    </app-card>
  }
</app-card>
