import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { CardComponent } from '@Ui/card/card.component';
import { FeatureFrameComponent } from '@Ui/feature-frame/feature-frame.component';
import { ProfileComponent } from './profile/profile.component';
import { AccountComponent } from './account/account.component';

@Component({
  selector: 'app-feature-user',
  standalone: true,
  imports: [
    CommonModule,
    FeatureFrameComponent,
    MatTabsModule,
    CardComponent,
    ProfileComponent,
    AccountComponent
  ],
  templateUrl: './user.component.html',
  styleUrl: './user.component.scss'
})
export class UserFeatureComponent {

}
