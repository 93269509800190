import { DashboardFeatureComponent } from '@Features/dashboard/dashboard.component';
import { UserFeatureComponent } from '@Features/user/user.component';
import { Routes } from '@angular/router';
import { APP_NAME } from './@constants/globals';
import { TrainerFeatureComponent } from '@Features/trainer/trainer.component';
import { TodoFeatureComponent } from '@Features/todo/todo.component';
import { TabletopFeatureComponent } from '@Features/tabletop/tabletop-dashboard.component';
import { DevToolsFeatureComponent } from '@Features/dev-tools/dev-tools.component';
import { LanguagesFeatureComponent } from '@Features/languages/languages-dashboard.component';
import { PlannerFeatureComponent } from '@Features/planner/planner.component';

export const APP_ROUTE_HANDLER: Routes = [
  {
    path: 'dashboard',
    title: APP_NAME,
    component: DashboardFeatureComponent,
  },
  {
    path: 'planner',
    title: APP_NAME + ' - Planner',
    component: PlannerFeatureComponent
  },
  {
    path: 'dev',
    title: APP_NAME + ' - Dev Tools',
    component: DevToolsFeatureComponent,
  },
  {
    path: 'tabletop',
    title: APP_NAME + ' - Tabletop',
    component: TabletopFeatureComponent,
  },
  {
    path: 'trainer',
    title: APP_NAME + ' - Trainer',
    component: TrainerFeatureComponent,
  },
  {
    path: 'profile',
    title: APP_NAME + ' - User',
    component: UserFeatureComponent,
  },
  {
    path: 'languages',
    title: APP_NAME + ' - Languages',
    component: LanguagesFeatureComponent,
  },
  {
    path: 'todo',
    title: APP_NAME + ' - To-do',
    component: TodoFeatureComponent,
  },
  {
    path: '**',
    redirectTo: '/dashboard',
    pathMatch: 'full'
  }
];
