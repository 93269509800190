import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { FitnessApiService } from '@Api/fitness.api.service';
import { IExercise } from '@Models/trainer';
import { CardComponent } from '@Ui/card/card.component';
import { ExerciseAddComponent } from './exercise-add/exercise-add.component';
import { ExerciseLibraryViewComponent } from '../../exercise-viewer/exercise-viewer.component';

@Component({
  selector: 'app-trainer-library-exercises',
  standalone: true,
  imports: [
    CommonModule,
    CardComponent,
    MatTabsModule,
    ExerciseAddComponent,
    ExerciseLibraryViewComponent
  ],
  templateUrl: './exercises.component.html',
  styleUrl: './exercises.component.scss'
})
export class TrainerExercisesComponent {
  exercises: IExercise[];
  columnsToRender: string[] = ['id', 'name', 'description' ];

  constructor(
    private _fitnessApiService: FitnessApiService
  ) {
    this._fitnessApiService.getAllExercises().then(exercises => {
      this.exercises = exercises
    });
  }

}
