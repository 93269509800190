<app-card class="routines-container">
  <table mat-table [dataSource]="routines" class="mat-elevation-z8">
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef> ID </th>
      <td mat-cell *matCellDef="let routine">{{ routine.id }}</td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> Name </th>
      <td mat-cell *matCellDef="let routine">{{ routine.name }}</td>
    </ng-container>

    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef> Description </th>
      <td mat-cell *matCellDef="let routine">{{ routine.description }}</td>
    </ng-container>

    <ng-container matColumnDef="category">
      <th mat-header-cell *matHeaderCellDef> Category </th>
      <td mat-cell *matCellDef="let routine">{{ routine.category }}</td>
    </ng-container>

    <ng-container matColumnDef="workouts">
      <th mat-header-cell *matHeaderCellDef> Workouts </th>
      <td mat-cell *matCellDef="let routine">{{ generateRenderableWorkouts(routine.workouts)}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnsToRender"></tr>
    <tr mat-row *matRowDef="let row; columns: columnsToRender;"></tr>
  </table>
</app-card>
