import { AppRouterService } from '@App/@services/app-router.service';
import { GlobalHelperService } from '@App/@services/global-helper.service';
import { IAppRoute } from '@Models/components';
import { CardComponent } from '@Ui/card/card.component';
import { FeatureFrameComponent } from '@Ui/feature-frame/feature-frame.component';
import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { ENVIRONMENT } from 'environments/environment';

export interface IExampleDashboardCard {
  title: string;
  content: string;
}

@Component({
  selector: 'app-feature-dashboard',
  standalone: true,
  imports: [
    CommonModule,
    FeatureFrameComponent,
    MatButtonModule,
    CardComponent,
    MatCardModule,
    MatIconModule
  ],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss'
})
export class DashboardFeatureComponent {
  envName = ENVIRONMENT.ENV_NAME;
  apiURL = ENVIRONMENT.API_URL;
  appRoutes: IAppRoute[];

  constructor(
    private _globalHelperService: GlobalHelperService,
    private _appRouterService: AppRouterService
  ) {
    this.appRoutes = this._globalHelperService.getSortedAppRoutes();
  }

  public navigateToRoute(routePath: string): void {
    this._appRouterService.navigateTo(routePath);
  }
}
