<div class="datacard">
  <h2>{{ datacard.name }}</h2>

  <app-card class="datacard__header"
    [justifyContent]="'space-between'"
    [flexDirection]="'row'"
  >
    <app-card class="characteristics">
      <ul>
        <li><strong>Movement:</strong> {{ datacard.characteristics.movement }}"</li>
        <li><strong>Toughness:</strong> {{ datacard.characteristics.toughness }}</li>
        <li><strong>Save:</strong> {{ datacard.characteristics.save }}+</li>
        <li><strong>Wounds:</strong> {{ datacard.characteristics.wounds }}</li>
        <li><strong>Leadership:</strong> {{ datacard.characteristics.leadership }}+</li>
        <li><strong>OC:</strong> {{ datacard.characteristics.oc }}</li>
      </ul>
    </app-card>

    <div class="keywords">
      <h3>Keywords: </h3>
      <p>
        @for (keyword of datacard.keywords; track $index) {
          {{ keyword }},
        }
      </p>
      <ul>
      </ul>
    </div>

    <div class="faction-keywords">
      <h3>Faction Keywords: </h3>
      <p>
        @for (factionKeyword of datacard.factionKeywords; track $index) {
          {{ factionKeyword }},
        }
      </p>
    </div>
  </app-card>

  <div class="datacard__equipment">
    <h3>Equipment</h3>
    <div class="ranged-weapons">
      <h4>Ranged Weapons</h4>
      <ul>
        @for (weapon of datacard.equipment.rangedWeapons; track weapon) {
          <li>
            <strong>
              {{ weapon.name }}</strong> -
              Range: {{ weapon.range }}",
              Attack: {{ weapon.attack }},
              BS: {{ weapon.ballisticSkill }}+
              Strength: {{ weapon.strength }},
              AP: -{{ weapon.armorPenetration }},
              Damage: {{ weapon.damage }},
          </li>
        }
      </ul>
    </div>

    <div class="melee-weapons">
      <h4>Melee Weapons</h4>
      <ul>
        @for (weapon of datacard.equipment.meleeWeapons; track weapon) {
          <li>
            <strong>{{ weapon.name }}</strong> -
            Range: Melee,
            Attack: {{ weapon.attack }},
            WS: {{ weapon.weaponSkill }}
            Strength: {{ weapon.strength }},
            AP: {{ weapon.armorPenetration }},
            Damage: {{ weapon.damage }},
          </li>
        }
      </ul>
    </div>

    <div class="wargear-options">
      <h4>Wargear Options</h4>
      <ul>
        @for (wargear of datacard.equipment.wargearOptions; track wargear) {
          <li>
            <strong>{{ wargear.name }}</strong> - {{ wargear.description }}
          </li>
        }
      </ul>
    </div>
  </div>


  <div class="unit-composition">
    <h3>Unit Composition</h3>
    <ul>
      @for (unit of datacard.unitComposition; track unit) {
        <li>
          <strong>{{ unit.name }}</strong> - Count: {{ unit.count }}
        </li>
      }
    </ul>
  </div>

  <div class="abilities">
    <h3>Abilities</h3>
    <ul>
      @for (ability of datacard.abilities; track ability) {
        <li>
          <strong>{{ ability.name }}</strong> -
          Count: {{ ability.name }}
          {{ ability.description}}
        </li>
      }
    </ul>
  </div>
</div>
