@switch (view) {
  @case ('table') {
    <ng-template [ngTemplateOutlet]="tableView"></ng-template>
  }
  @case ('card') {
    <ng-template [ngTemplateOutlet]="card"></ng-template>
  }
  @case ('card-drag-drop') {
    <ng-template [ngTemplateOutlet]="cardDragDropView"></ng-template>
  }
}

<ng-template #tableView>
  <app-card class="cards-table" [width]="'oneHundredPercent'">
    <table mat-table [dataSource]="exercises" class="mat-elevation-z8">
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef> ID </th>
        <td mat-cell *matCellDef="let exercise"> {{exercise.id}} </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Name </th>
        <td mat-cell *matCellDef="let exercise"> {{exercise.name}} </td>
      </ng-container>

      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef> Description </th>
        <td mat-cell *matCellDef="let exercise"> {{exercise.description}} </td>
      </ng-container>

      <ng-container matColumnDef="category">
        <th mat-header-cell *matHeaderCellDef> Category </th>
        <td mat-cell *matCellDef="let exercise"> {{exercise.category}} </td>
      </ng-container>

      <ng-container matColumnDef="weighted">
        <th mat-header-cell *matHeaderCellDef> Weighted </th>
        <td mat-cell *matCellDef="let exercise"> {{exercise.weighted ? 'Y' : 'N' }} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columnsToRender"></tr>
      <tr mat-row *matRowDef="let row; columns: columnsToRender;"></tr>
    </table>
  </app-card>
</ng-template>

<ng-template #card>
  <app-card class="cards-container">
    @for (card of exercises; track $index) {
      <app-card class="card" [borderStyle]="'shadow'">
        <h3>{{ card.name }}</h3>
        <p>{{ card.category }}</p>
      </app-card>
    }
  </app-card>
</ng-template>

<ng-template #cardDragDropView>
  <app-card class="cards-container">
    @for (card of exercises; track $index) {
      <app-card class="card" cdkDrag>
        <h3>{{ card.name }}</h3>
        <p>{{ card.category }}</p>
      </app-card>
    }
  </app-card>
  <app-card>
    <div cdkDropList
    #exerciseADropList="cdkDropList"
    [cdkDropListData]="exerciseTitle"
    [cdkDropListConnectedTo]="workoutExerciseDropList"
    (cdkDropListDropped)="drop($event)"
    >
    @for (card of exerciseTitle; track $index) {
      <app-card class="card" cdkDrag>
        <h3>{{ card }}</h3>
      </app-card>
    }
    </div>
  </app-card>
  <app-card>
    <div cdkDropList
    #exerciseBDropList="cdkDropList"
    [cdkDropListData]="workoutExerciseDropList"
    [cdkDropListConnectedTo]="exerciseTitle"
    (cdkDropListDropped)="drop($event)"
    >
    @for (card of workoutExerciseDropList; track $index) {
      <app-card class="card" cdkDrag>
        <h3>{{ card }}</h3>
      </app-card>
    }
    </div>
  </app-card>
</ng-template>
