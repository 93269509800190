import { IExercise } from '@App/@models/trainer';
import { ButtonComponent } from '@Ui/button/button.component';
import { CardComponent } from '@Ui/card/card.component';
import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';

@Component({
  selector: 'app-exercise-add',
  standalone: true,
  imports: [
    CommonModule,
    CardComponent,
    ButtonComponent,
    FormsModule,
    MatSelectModule,
    MatOptionModule,
  ],
  templateUrl: './exercise-add.component.html',
  styleUrl: './exercise-add.component.scss'
})
export class ExerciseAddComponent {
  exercises: IExercise[] = [];
  newExerciseInput: IExercise = {} as unknown as IExercise;

  ngOnInit(): void {
    this.insertMockTodoItems();
  }

  insertMockTodoItems(): void {
    const mockTodoItems: IExercise[] = [
      {
        id: 1,
        name: 'First Exercise',
        description: 'An Exercise',
        category: 'Calisthenics',
      },
      {
        id: 2,
        name: 'Second Exercise',
        description: 'An Exercise',
        category: 'Calisthenics'
      },
    ]

    this.exercises.push(...mockTodoItems)
  }

  onAddExerciseClick(): void {
    if (this.verifyAddItemInputIsValid()) {
      console.log(`Added item:\n`, this.newExerciseInput)
      this.exercises.push(this.newExerciseInput);
    }
  }

  verifyAddItemInputIsValid(): boolean {
    const hasMandatoryFields = !!this.newExerciseInput.name && !!this.newExerciseInput.category;
    return hasMandatoryFields;
  }
}
