import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { FeatureFrameComponent } from '@Ui/feature-frame/feature-frame.component';
import { TrainerExercisesComponent } from './exercises/exercises.component';
import { TrainerWorkoutsComponent } from './workouts/workouts.component';
import { TrainerRoutinesComponent } from './routines/routines.component';

@Component({
  selector: 'app-trainer-library',
  standalone: true,
  imports: [
    CommonModule,
    FeatureFrameComponent,
    MatTableModule,
    MatDividerModule,
    MatTabsModule,
    TrainerExercisesComponent,
    TrainerWorkoutsComponent,
    TrainerRoutinesComponent
  ],
  templateUrl: './trainer-library.component.html',
  styleUrl: './trainer-library.component.scss'
})
export class TrainerLibraryComponent {

}
