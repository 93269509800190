import { IExercise, IExerciseDBModel, IRoutine, IWorkout } from "@App/@models/trainer";
import { Injectable } from "@angular/core";
import { APIService } from "./api.service";

@Injectable({ providedIn: 'root' })
export class FitnessApiService {
  endpointPrefix = 'fitness';

  constructor(
    private _apiService: APIService
  ) {
  }

  getAllExercises(): Promise<IExercise[]> {
    return this._apiService.get<IExerciseDBModel[]>(`${this.endpointPrefix}/exercises`).then(exercises => {
      const mappedExercises: IExercise[] = exercises.map(e => {
        const exercise: IExercise = {
          id: e.id,
          name: e.name,
          description: e.description,
          category: e.category,
          variant: e.variant,
          primaryMuscle: e.primary_muscle,
          secondaryMuscle: e.secondary_muscle,
          weighted: e.weighted
        };
        return exercise;
      });
      return Promise.resolve(mappedExercises);
    })
  }

  getAllWorkouts(): Promise<IWorkout[]> {
    return this._apiService.get(`${this.endpointPrefix}/workouts`);
  }

  getAllRoutines(): Promise<IRoutine[]> {
    return this._apiService.get(`${this.endpointPrefix}/routines`);
  }
}
