import { ButtonVariation, ButtonSize } from '@Models/types';
import { CardComponent } from '@Ui/card/card.component';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-button',
  standalone: true,
  imports: [
    CardComponent
  ],
  templateUrl: './button.component.html',
  styleUrl: './button.component.scss'
})
export class ButtonComponent {
  @Input() label: string = '';
  @Input() variation: ButtonVariation = 'primary';
  @Input() size: ButtonSize = 'small';
  @Output() clickEvent = new EventEmitter<void>();

  onClick() {
    this.clickEvent.emit();
  }
}
