<app-card class="workouts-container">
  <table mat-table [dataSource]="workouts" class="mat-elevation-z8">
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef> ID </th>
      <td mat-cell *matCellDef="let workout">{{ workout.id }}</td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> Name </th>
      <td mat-cell *matCellDef="let workout">{{ workout.name }}</td>
    </ng-container>

    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef> Description </th>
      <td mat-cell *matCellDef="let workout">{{ workout.description }}</td>
    </ng-container>

    <ng-container matColumnDef="category">
      <th mat-header-cell *matHeaderCellDef> Category </th>
      <td mat-cell *matCellDef="let workout">{{ workout.category }}</td>
    </ng-container>

    <ng-container matColumnDef="exercises">
      <th mat-header-cell *matHeaderCellDef> Exercises </th>
      <td mat-cell *matCellDef="let workout">
        <app-card [flexDirection]="'column'">
          <p *ngFor="let exercise of workout.exercises">{{ exercise.name }}</p>
        </app-card>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnsToRender"></tr>
    <tr mat-row *matRowDef="let row; columns: columnsToRender;"></tr>
  </table>
</app-card>
