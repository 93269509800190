import { HARDCODED_EXERCISES } from '@Constants/trainer-dashboard-hardcoded-data';
import { W40K_DATACARDS } from '@Constants/w40k-hardcoded';
import { CardComponent } from '@Ui/card/card.component';
import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { W40kDatacardComponent } from './w40k-datacard/w40k-datacard.component';

@Component({
  selector: 'app-feature-tabletop',
  standalone: true,
  imports: [
    CommonModule,
    CardComponent,
    W40kDatacardComponent
  ],
  templateUrl: './tabletop-dashboard.component.html',
  styleUrl: './tabletop-dashboard.component.scss'
})
export class TabletopFeatureComponent implements OnInit {
  hardcodedExercises = HARDCODED_EXERCISES;
  warhammer40kDatacards = W40K_DATACARDS;

  ngOnInit(): void {
  }

}
