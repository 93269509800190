@if (!dynamicWorkout) {
  <h3>Would you like to create a new Dynamic Workout?</h3>
  <div class="main-controls">
    <button mat-button class="ps-button--primary" (click)="onCreateDynamicWorkout()">
      <i class="bi bi-plus-lg"></i>Create Dynamic Workout
    </button>
    <button mat-button class="ps-button--primary" (click)="onLoadDynamicWorkout()">
      <i class="bi bi-plus-lg"></i>Load Last Dynamic Workout
    </button>
  </div>
} @else if (columnsToRender[tableViewMode].length > 0) {
  <div class="view-controls">
    <button mat-button class="ps-button--primary" (click)="onToggleSimpleView()">
      <i class="bi bi-plus-lg"></i>{{ tableViewMode }} View
    </button>
    <button mat-button class="ps-button--primary" (click)="onSaveDynamicWorkout()">
      <i class="bi bi-plus-lg"></i>Save
    </button>
    <button mat-button class="ps-button--primary" (click)="onResetDynamicWorkout()">
      <i class="bi bi-plus-lg"></i>Reset
    </button>
  </div>

  <div class="flex justify-between">
    <div class="table-container">
      <table mat-table [dataSource]="dynamicWorkout.exercises">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef> Exercise </th>
          <td mat-cell *matCellDef="let exercise"> {{exercise.name}} </td>
        </ng-container>

        <ng-container matColumnDef="category">
          <th mat-header-cell *matHeaderCellDef> Category </th>
          <td mat-cell *matCellDef="let exercise"> {{exercise.category}} </td>
        </ng-container>

        <ng-container matColumnDef="variant">
          <th mat-header-cell *matHeaderCellDef> Variant </th>
          <td mat-cell *matCellDef="let exercise"> {{exercise.variant}} </td>
        </ng-container>

        <ng-container matColumnDef="primaryMuscle">
          <th mat-header-cell *matHeaderCellDef> Primary Muscle </th>
          <td mat-cell *matCellDef="let exercise"> {{exercise.primaryMuscle}} </td>
        </ng-container>

        <ng-container matColumnDef="secondaryMuscle">
          <th mat-header-cell *matHeaderCellDef> Secondary Muscle </th>
          <td mat-cell *matCellDef="let exercise"> {{exercise.secondaryMuscle}} </td>
        </ng-container>

        <ng-container matColumnDef="addRemove">
          <th mat-header-cell *matHeaderCellDef> Remove </th>
          <td mat-cell *matCellDef="let exercise">
            <button mat-fab class="ps-button--bad ps-button--xs" (click)="onRemoveExercise(exercise)">
              <i class="bi bi-x-lg"></i>
            </button>
          </td>
        </ng-container>

        <ng-container matColumnDef="setControls">
          <th mat-header-cell *matHeaderCellDef> Sets Left </th>
          <td mat-cell *matCellDef="let exercise">
            <button mat-fab class="ps-button--neutral ps-button--xxs" (click)="onRemoveExercise(exercise)">
              <i class="bi bi-caret-up-fill"></i>
            </button>
            <button mat-fab class="ps-button--neutral ps-button--xxs" (click)="onRemoveExercise(exercise)">
              <i class="bi bi-caret-down-fill"></i>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToRender[tableViewMode]"></tr>
        <tr mat-row *matRowDef="let row; columns: columnsToRender[tableViewMode];"></tr>
      </table>
    </div>

    @if (tableViewMode !== 'Workout') {
      <div class="table-container">
        <table mat-table [dataSource]="exerciseLibrary">
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> Exercise </th>
            <td mat-cell *matCellDef="let exercise"> {{exercise.name}} </td>
          </ng-container>

          <ng-container matColumnDef="category">
            <th mat-header-cell *matHeaderCellDef> Category </th>
            <td mat-cell *matCellDef="let exercise"> {{exercise.category}} </td>
          </ng-container>

          <ng-container matColumnDef="variant">
            <th mat-header-cell *matHeaderCellDef> Variant </th>
            <td mat-cell *matCellDef="let exercise"> {{exercise.variant}} </td>
          </ng-container>

          <ng-container matColumnDef="primaryMuscle">
            <th mat-header-cell *matHeaderCellDef> Primary Muscle </th>
            <td mat-cell *matCellDef="let exercise"> {{exercise.primaryMuscle}} </td>
          </ng-container>

          <ng-container matColumnDef="secondaryMuscle">
            <th mat-header-cell *matHeaderCellDef> Secondary Muscle </th>
            <td mat-cell *matCellDef="let exercise"> {{exercise.secondaryMuscle}} </td>
          </ng-container>

          <ng-container matColumnDef="addRemove">
            <th mat-header-cell *matHeaderCellDef> Add </th>
            <td mat-cell *matCellDef="let exercise">
              <button mat-fab class="ps-button--good ps-button--xs" (click)="onAddExercise(exercise)">
                <i class="bi bi-plus-lg"></i>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="columnsToRender[tableViewMode]"></tr>
          <tr mat-row *matRowDef="let row; columns: columnsToRender[tableViewMode];"></tr>
        </table>
      </div>
    }
  </div>
} @else {
  <p>No Data</p>
}
