import { IExercise } from '@App/@models/trainer';
import { ExerciseTableView } from '@Models/types';
import { CardComponent } from '@Ui/card/card.component';
import { CdkDragDrop, DragDropModule, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatTableModule } from '@angular/material/table';

@Component({
  selector: 'app-exercise-viewer',
  standalone: true,
  imports: [
    CommonModule,
    CardComponent,
    MatTableModule,
    DragDropModule,
  ],
  templateUrl: './exercise-viewer.component.html',
  styleUrl: './exercise-viewer.component.scss'
})
export class ExerciseLibraryViewComponent {
  @Input({ required: true }) view: ExerciseTableView;
  @Input({ required: true }) exercises: IExercise[];
  @Input({ required: true }) columnsToRender: string[];

  exerciseTitle: string[] = ['A', 'B', 'C'];
  exerciseDropList: IExercise[] = [
    {
      id: 1,
      name: 'Bench Press',
      description: 'The bench press is an upper-body weight training exercise in which the trainee presses a weight upwards while lying on a weight training bench.',
      category: 'Weightlifting',
      variant: 'Barbell',
    }
  ];
  workoutExerciseDropList: string[] = ['D'];

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
  }
}
